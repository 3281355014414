import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeroLayout } from "../components/hero-layout";
import { getQueryParam } from "../utils/query";

export const ErrorPage = ({ location }) => {
  const message =
    getQueryParam("message", location) ||
    (location.state && location.state.message);
  const errorCode = location.state && location.state.errorCode;

  return (
    <Layout withMaxWidth={false}>
      <SEO title={"Error"} />
      <HeroLayout>
        <div className="text-content-container confirm-email">
          <h2 className="mdc-typography--headline4">
            Oops... Looks like something went wrong on our end
          </h2>

          <p className="mdc-typography--subtitle1">
            Please try again, and if the problem persists, contact{" "}
            <a href={"mailto:support@remeet.com"}>support@remeet.com</a>{" "}
            {(errorCode || message) && (
              <span className={"error"}>
                (Error: {errorCode || message || "Unknown"})
              </span>
            )}
            .
          </p>
        </div>
      </HeroLayout>
    </Layout>
  );
};

export default ErrorPage;
