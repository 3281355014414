import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  mtrLogoBlue,
  mtrLogoGray,
  mtrLogoGreen,
  mtrLogoOrange,
  mtrLogoPink,
  mtrLogoPurple,
} from "../utils/colors";

// import { motion, useViewportScroll, useTransform } from "framer-motion";
import { useForceUpdate } from "../utils/use-force-update";
import { ProductNameContext } from "../context";

// const MeetterSquare = ({
//   x,
//   y,
//   size,
//   color,
//   rotate,
//   floatConfig: {
//     end = -2,
//     duration = 3,
//     start = 0,
//     rotateStart = 0,
//     rotateEnd = 0,
//   } = {
//     end: -2,
//     duration: 3,
//     start: 0,
//     rotateStart: 0,
//     rotateEnd: 0,
//   },
// }) => {
//   function template({ x, y, translateX, translateY, rotateY }) {
//     return `translateY(${translateY}) rotate(${rotateY})`;
//   }
//
//   return (
//     <motion.g style={{ rotate: rotate }}>
//       <motion.rect
//         transformTemplate={template}
//         x={x}
//         y={y}
//         translateX={0}
//         translateY={start}
//         rx={size / 4}
//         ry={size / 4}
//         width={size}
//         height={size}
//         fill={color}
//         rotateY={rotateStart}
//         initial={{ translateY: start, rotateY: rotateStart }}
//         animate={{ translateY: end, rotateY: rotateEnd }}
//         transition={{ yoyo: Infinity, duration, ease: "easeInOut" }}
//       />
//     </motion.g>
//   );
// };
// const DynamicBackground = () => {
//   const [height, setHeight] = useState(0);
//   const forceUpdate = useForceUpdate();
//   const { scrollY } = useViewportScroll();
//
//   const y = useTransform(scrollY, [0, height * 0.25], [0, -height * 0.1], {
//     clamp: false,
//   });
//
//   const opacity = useTransform(scrollY, [0, height * 0.5], [1, 0], {
//     clamp: false,
//   });
//
//   const rotate = useTransform(scrollY, [0, height * 0.25], [0, 90], {
//     clamp: false,
//   });
//
//   useLayoutEffect(() => {
//     setHeight(window.innerHeight);
//   }, []);
//
//   // workaround for https://github.com/meetter/meetter/issues/302
//   useEffect(() => {
//     if (!height) return;
//     try {
//       document.dispatchEvent(new CustomEvent("scroll"));
//     } catch (e) {}
//     forceUpdate();
//   }, [height]);
//
//   return (
//     <motion.svg
//       viewBox="0 0 100 20"
//       style={{
//         overflow: "visible",
//         position: "absolute",
//         bottom: -44,
//         height: "auto",
//         zIndex: "-1",
//         y,
//         opacity,
//       }}
//     >
//       <MeetterSquare
//         x={2}
//         y={1}
//         size={14}
//         color={mtrLogoGray}
//         floatConfig={{ start: -2, end: 0, rotateStart: 15, rotateEnd: 7 }}
//         rotate={rotate}
//       />
//       <MeetterSquare
//         x={24}
//         y={10}
//         size={8}
//         color={mtrLogoPink}
//         floatConfig={{
//           start: 2,
//           end: -2,
//           duration: 4,
//           rotateStart: -20,
//           rotateEnd: 10,
//         }}
//         rotate={rotate}
//       />
//       <MeetterSquare
//         x={42}
//         y={7}
//         size={6}
//         color={mtrLogoOrange}
//         floatConfig={{
//           start: 3,
//           end: -3,
//           duration: 5,
//           rotateStart: -45,
//           rotateEnd: 10,
//         }}
//         rotate={rotate}
//       />
//       <MeetterSquare
//         x={62}
//         y={2}
//         size={16}
//         color={mtrLogoBlue}
//         floatConfig={{
//           start: 0,
//           end: -2,
//           duration: 4,
//           rotateStart: -20,
//           rotateEnd: 30,
//         }}
//         rotate={rotate}
//       />
//       <MeetterSquare
//         x={82}
//         y={6}
//         size={8}
//         color={mtrLogoPurple}
//         floatConfig={{
//           start: -4,
//           end: 0,
//           duration: 4,
//           rotateStart: 60,
//           rotateEnd: 80,
//         }}
//         rotate={rotate}
//       />
//       <MeetterSquare
//         x={92}
//         y={-8}
//         size={12}
//         color={mtrLogoGreen}
//         floatConfig={{
//           start: -2,
//           end: 2,
//           duration: 6,
//           rotateStart: 20,
//           rotateEnd: 30,
//         }}
//         rotate={rotate}
//       />
//     </motion.svg>
//   );
// };

export const HeroLayout = ({ children, withStaticBackground = true }) => {
  return (
    <section
      className={`top-section top-section--static-${withStaticBackground}`}
    >
      <div className="top-section-content content-container">
        <div className="inner-content">{children}</div>
      </div>
      {/*{!withStaticBackground && <DynamicBackground />}*/}
    </section>
  );
};
